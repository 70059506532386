<template>
  <div>
    <h2 v-html="$t('search_segmentation_overview_title')"></h2>
    <!--Meldungs-ID -->
    <v-text-field
      id="search_segmentation_form_id"
      v-model="searchCardStore.formId"
      :label="$t('search_segmentation_form_id')"
      type="number"
      @keyup.enter="search"
    />
    <!-- Status von -->
    <v-select
      id="search_segmentation_status_from"
      v-model="searchCardStore.statusFrom"
      :label="$t('search_segmentation_status_from')"
      :items="segmentationStatusesIncVirtual"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- Status bis -->
    <v-select
      id="search_segmentation_status_to"
      v-model="searchCardStore.statusTo"
      :label="$t('search_segmentation_status_to')"
      :items="segmentationStatusesIncVirtual"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- Korrektur -->
    <v-select
      id="search_segmentation_is_correction"
      v-model="searchCardStore.correction"
      :label="$t('search_segmentation_correction')"
      :items="segmentation_is_correction"
      item-title="text"
      item-value="value"
      clearable
      @keyup.enter="search"
    />
    <!-- Abschlussmonat von -->
    <DbmMonthPicker
      name="search_segmentation_valid_from"
      id="search_segmentation_valid_from"
      v-model="searchCardStore.validFrom"
      :dateLabel="$t('search_segmentation_valid_from')"
      :clearable="true"
    />
    <!-- Abschlussmonat bis -->
    <DbmMonthPicker
      name="search_segmentation_valid_until"
      id="search_segmentation_valid_until"
      v-model="searchCardStore.validUntil"
      :dateLabel="$t('search_segmentation_valid_until')"
      :clearable="true"
      returnType="lastOfMonth"
    />
    <!-- Periodizität -->
    <v-select
      id="search_segmentation_period_id"
      v-model="searchCardStore.periodId"
      :label="$t('search_segmentation_period_id')"
      :items="formPeriods"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />

    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- expanded here -->
      <!-- Zuletzt bearbeitet von -->
      <DbmDatePicker v-model="searchCardStore.modificationFrom" :dateLabel="$t('search_segmentation_modification_from')" :clearable="true" />
      <!-- Zuletzt bearbeitet bis -->
      <DbmDatePicker v-model="searchCardStore.modificationUntil" :dateLabel="$t('search_segmentation_modification_until')" :clearable="true" />
      <!-- Ablage-Nr -->
      <v-text-field id="search_segmentation_form_code" v-model="formCode" :label="$t('search_segmentation_form_code')" type="text" @keyup.enter="search" />
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex'
import baseSearchVue from './baseSearch.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'

import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { useSearchSegmentationStore } from '@/store/SearchSegmentationStore'
import { useFeedingsStore } from '@/store/enums/FeedingsStore'
import { useFormPeriodsStore } from '@/store/enums/FormPeriodsStore'
import { useSegmentationStatusStore } from '@/store/enums/SegmentationStatusStore'
import { useReportingObligationsStore } from '@/store/enums/ReportingObligationsStore'
import { useSegmentationTypesStore } from '@/store/enums/SegmentationTypesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchSegmentation',
  extends: baseSearchVue,
  props: {
    forDifference: { type: Boolean, default: false, required: false }
  },
  data() {
    return {
      expanded: false,
      segmentation_is_correction: [
        {
          value: 1,
          text: this.$t('search_segmentation_correction_yes')
        },
        { value: 0, text: this.$t('search_segmentation_correction_no') }
      ]
    }
  },
  computed: {
    segmentationStatusesIncVirtual() {
      return useSegmentationStatusStore().segmentationStatusesIncVirtual
    },
    segmentationTypes() {
      return useSegmentationTypesStore().segmentationTypes
    },
    feedings() {
      return useFeedingsStore().feedings
    },
    reportingObligations() {
      return useReportingObligationsStore().reportingObligations
    },
    formPeriods() {
      return useFormPeriodsStore().formPeriods
    }
  },

  components: {
    DbmMonthPicker: DbmMonthPicker,
    DbmDatePicker: DbmDatePicker
  },
  beforeMount() {
    this.setSearchCardStore(useSearchSegmentationStore(), true)
    useFeedingsStore().fetchFeedings()
    useFormPeriodsStore().fetchFormPeriods()
    useSegmentationStatusStore().fetchSegmentationStatusesIncVirtual()
    useReportingObligationsStore().fetchReportingObligations()
  }
})
</script>
